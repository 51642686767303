export const hrEnums = {
    SR_NO: "Sr No.",
    STAFF_NAME: 'Staff Name',
    JOINING_DATE: 'Joining Date',
    ACTION: 'Action',
    EDIT: 'Edit',
    VIEW: 'View',
    ADD: "Add",
    ID: "ID",
    DISCARD: 'Discard',
    STAFF: 'Staff',
    STAFF_ID: 'Staff ID',
    centerID: "19",
    LAST_STAFF_ID: 'Last Staff ID:',
    PROFILE_INFO: 'Profile Information',
    GENERAL_INFO: 'General Information',
    PERSONAL_INFO: 'Personal Information',
    EMPLOYMENT_INFO: 'Employment Information',
    BANK_INFO: 'Bank Information',
    FIRST_NAME: 'First Name',
    MIDDLE_NAME: 'Middle Name',
    LAST_NAME: 'Last Name',
    ROLE: 'Role',
    STAFF_TYPE: 'Staff Type',
    REPORTING_MANAGER: 'Reporting Manager',
    CENTER: 'Center',
    SALARY_CYCLE: 'Salary Cycle',
    WEEK_OFF: 'Week Off',
    HOLIDAY_TEMPLATE: 'Holiday Template',
    LEAVE_TEMPLATE: 'Leave Template',
    SHIFT: 'Shift',
    SALARY_ACCESS: 'Salary Access',
    ATTENDANCE_MODE: 'Attendance Mode',
    EMAIL: 'Email',
    GENDER: 'Gender',
    DOB: 'Date of Birth',
    MARITAL_STATUS: 'Marital Status',
    BLOOD_GRP: 'Blood Group',
    EMERGENCY_CONTACT: 'Emergency Contact No.',
    PHYSICALLY_CHALLENGED: 'Physically Challenged',
    FATHER_NAME: "Father Name",
    MOTHER_NAME: "Mother Name",
    SPOUSE_NAME: "Spouse Name",
    PERMANENT: 'Permanent',
    CURRENT: 'Current',
    CURRENT_ADDRESS: 'Current Address',
    PERMANENT_ADDRESS: 'Permanent Address',
    ADDRESS_LINE1: 'Address Line1',
    ADDRESS_LINE2: 'Address Line2',
    STATE: 'State',
    DISTRICT: 'District',
    POSTAL_CODE: 'Postal Code',
    UAN: 'UAN Number',
    PAN: 'PAN Number',
    REGULAR: 'Regular',
    NO: 'No',
    MARRIED: 'Married',
    MALE: 'Male',
    INVALID_EMAIL: "Invalid email",
    AADHAR_NUMBER: 'Aadhar Number',
    NAME_OF_BANK: 'Name of Bank',
    IFSC_CODE: 'IFSC Code',
    ACCOUNT_NO: 'Account Number',
    ACCOUNT_HOLDER_NAME: 'Name of Account Holder',
    CURRENT_CHECKBOX: "Same as Current Address",
    MONTHLY_SALARY: "Monthly Salary",
    MONTHLY_SALARY_INR: "Monthly Salary(INR)",
    MONTHLY_ALLOWANCE_INR: "Monthly Special Allowance(INR)",
    MONTHLY_ALLOWANCE: "Monthly Special Allowance",
    DOB_VALIDATION: "Date of birth cannot be today or in the future",
    DOCUMENT: "Document",
    PHYSICALLY_CHALLENGED_OP: [
        { id: "yes", label: "Yes", value: "Yes" },
        { id: "no", label: "No", value: "No" },
    ],
    STAFF_TYPE_OP: [
        { label: "Regular", value: "Regular" },
        { label: "Contract", value: "Contract" },
        { label: "Work Basis", value: "Work Basis" },
    ],
    WEEK_OFF_OP: [
        { label: "Sunday", value: "Sunday" },
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday" },
    ],
    SELECTED_WEEK: [
        { label: "Saturday", value: "Saturday" },
        { label: "Saturday1", value: "Saturday1" },
    ],
    BLOOD_GRP_OP: [
        { label: "A+", value: "A+" },
        { label: "A-", value: "A-" },
        { label: "B+", value: "B+" },
        { label: "B-", value: "B-" },
        { label: "O+", value: "O+" },
        { label: "O-", value: "O-" },
        { label: "AB+", value: "AB+" },
        { label: "AB-", value: "AB-" },
    ],
    REPORTING_MANAGER_OP: [
        { label: "staff1", value: "staff1" },
        { label: "staff2", value: "staff2" },
        { label: "staff3", value: "staff3" },
    ],
    SHIFT_TYPE_OP: [
        { label: "Fixed", value: "Fixed" },
        { label: "Evening", value: "Evening" },
        { label: "Night", value: "Night" },
        { label: "Weekend", value: "Weekend" },
        { label: "Overtime", value: "Overtime" },
    ],
    SALARY_ACCESS_OP: [
        { label: "Disabled", value: "Disabled" },
        { label: "Current_cycle", value: "Current_cycle" },
        { label: "Previous_cycle", value: "Previous_cycle" },
    ],
    ATTENDANCE_MODE_OP: [
        { label: "Manual", value: "Manual" },
        { label: "Selfie_and_location_based", value: "Selfie_and_location_based" },
    ],
    MARITAL_STATUS_OP: [
        { label: "Single", value: "Single" },
        { label: "Married", value: "Married" },
    ],
    classData: [
        {
            id: "1",
            staff_name: "Mohan Das",
            contact: "9826472999",
            email_id: "xyz@gmail.com",
            joining_date: "10/05/2023",
        },
        {
            id: "2",
            staff_name: "Amit Kumar",
            contact: "9826472518",
            email_id: "xyz@gmail.com",
            joining_date: "02/02/2023",
        },
    ],
    staffData:
    {
        first_name: "Mohan",
        middle_name: "Ram",
        last_name: "Kumar",
        designation: "Option1",
        id: "23",
        staff_type: "Regular",
        contact_no: "9012345823",
        reporting_manager: "Amit Das",
        center: "iscon",
        salary_cycle: "1",
        week_off: "Sunday",
        holiday_template: "holiday",
        leave_template: "leave policy",
        shift: "10AM to 8PM",
        role: "HO",
        salary_access: "Disabled",
        attendance_mode: "Manual",
        email: "mohan@gmail.com",
        gender: "male",
        dob: "1995-05-04",
        dobv: "03/05/1995",
        marital_status: "Single",
        blood_group: "O+",
        emergency_contact: "9826472999",
        father_name: "Ram Sharma",
        mother_name: "Vidhya Sharma",
        spouse_name: "N/A",
        physically_challenged: "No",
        address_line1: "Solaris",
        address_line2: "Bhuyangdev",
        district: "Ahmedabad",
        state: "Gujarat",
        postal_code: "382330",
        joining_date: "2023-10-05",
        joining_datev: "05/03/2023",
        uan: "9826472999947397",
        pan: "GTIOJ4653J",
        aadhar: "965327658123",
        bank_name: "SBI",
        ifsc_code: "98264729999826472",
        account_number: "SBI76290",
        account_holder_name: 'Amit Kumar',
    },

};
export const formFields = {
    profile_section: 'profile',
    personal_section: 'personal',
    general_section: 'general',
    bank_section: 'bank',
    employment_section: 'employment',
    first_name: 'first_name',
    middle_name: 'middle_name',
    last_name: 'last_name',
    designation: 'designation',
    role: 'role',
    staff_type: 'staff_type',
    email: 'email',
    contact_no: 'contact_no',
    center: 'center',
    reporting_manager: 'reporting_manager',
    staff_id: 'staff_id',
    week_off: 'week_off',
    holiday_template: 'holiday_template',
    leave_template: 'leave_template',
    shift: 'shift',
    salary_access: 'salary_access',
    attendance_mode: 'attendance_mode',
    gender: 'gender',
    dob: 'dob',
    blood_group: 'blood_group',
    emergency_contact: 'emergency_contact',
    physically_challenged: 'physically_challenged',
    father_name: 'father_name',
    mother_name: 'mother_name',
    marital_status: 'marital_status',
    spouse_name: 'spouse_name',
    address_line1: 'address_line1',
    address_line2: 'address_line2',
    state: 'state',
    district: 'district',
    postal_code: 'postal_code',
    permanent_address_line1: 'permanent_address_line1',
    permanent_address_line2: 'permanent_address_line2',
    permanent_state: 'permanent_state',
    permanent_district: 'permanent_district',
    permanent_postal_code: 'permanent_postal_code',
    bank_name: 'bank_name',
    ifsc: 'ifsc',
    account_number: 'account_number',
    account_holder_name: 'account_holder_name',
    joining_date: 'joining_date',
    uan: 'uan',
    pan: 'pan',
    aadhar_number: 'aadhar_number',
    monthly_salary: 'monthly_salary',
    monthly_special_allowance: 'monthly_special_allowance'
};
export const PHYSICALLY_CHALLENGED_OP = [
    { id: "Yes", label: "Yes", value: "Yes" },
    { id: "No", label: "No", value: "No" },
];
export const GENDER_OP = [
    { id: "Male", value: "Male", label: "Male", labelSuffix: "" },
    { id: "Female", value: "Female", label: "Female", labelSuffix: "" },
    { id: "Other", value: "Other", label: "Other", labelSuffix: "" },
];