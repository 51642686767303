import React, { useEffect, useState } from "react";
import { editLeaveApproval, leaveApprovalList } from "../../Api/ApprovalApi";
import { checkStatusCodeSuccess, extractMonthWithName, handleArrayResponse, handleResponse, TruncateWithTooltip } from "../../Constant/common";
import { RiArrowDownSLine, RiArrowUpSLine, RiSearchLine } from "react-icons/ri";
import BaseButton from "../../BaseComponents/BaseButton";
import { approvalLabel } from "../../Constant/HR/attendanceApproval";
import Spinner from "../../BaseComponents/BaseLoader";
import BaseCheckbox from "../../BaseComponents/BaseCheckbox";
import CustomPagination from "../../BaseComponents/BasePagination";
import { useSelectedCenter } from "../CenterContext";
import BaseModal from "../../BaseComponents/BaseModal";
import { notFound } from "../../Constant";
import { toast } from "react-toastify";
import { leaveApprovalTitle } from "../../Constant/title";

const LeaveApproval = () => {
  document.title = leaveApprovalTitle;
  const [groupedLeaveList, setGroupedLeaveList] = useState({});
  const [expandedSections, setExpandedSections] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [summaryTooltipOpen, setSummaryTooltipOpen] = useState(false);
  const [desTooltipOpen, setDesTooltipOpen] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const { selectedCenterPayload } = useSelectedCenter();
  let leaveData;

  const fetchData = (query) => {
    setLoader(true);
    const payload = {
      ...selectedCenterPayload,
      search: query || "",
      pageSize: pageSize,
      pageNumber: currentPage,
      order: ["from_date", "desc"],
    };
    leaveApprovalList(payload)
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          leaveData = resp?.data?.listOfLeave || [];
          setNoResult(resp?.data?.listOfLeave?.length <0);
          setTotalPages(resp?.data?.totalPages);
          setTotalRecords(resp?.data?.totalRecordsCount);
          const groupedData = groupByMonthAndYear(leaveData);
          setGroupedLeaveList(groupedData);
        } else {
          setNoResult(true);
          setGroupedLeaveList({});
        }
      })
      .catch(() => {
        setNoResult(true);
        setGroupedLeaveList({});
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    setSelectedIds([]);
    fetchData(searchQuery);
  }, [searchQuery, currentPage, pageSize]);

  const editData = (approve) => {
    setLoader(true);
    editLeaveApproval({ leaveIds: selectedIds, leave_status: approve ? approvalLabel.Approved : approvalLabel.Rejected })
      .then((resp) => {
        if (checkStatusCodeSuccess(resp?.statusCode)) {
          const leaveData = resp?.data?.listOfLeave || [];
          const groupedData = groupByMonthAndYear(leaveData);
          setGroupedLeaveList(groupedData);
          setSelectedIds([]);
          fetchData();
          toast.success(resp?.message);
        } else {
          setGroupedLeaveList({});
          toast.error(resp?.message);
        }
      })
      .catch((err) => {
        setGroupedLeaveList({});
        handleArrayResponse(err);
      })
      .finally(() => {
        setLoader(false);
        setModalOpen(false);
      });
  };

  const groupByMonthAndYear = (leaveData) => {
    return leaveData?.reduce((acc, leave) => {
      const monthYear = extractMonthWithName(leave.from_date);
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(leave);
      return acc;
    }, {});
  };

  const handleSelectEntry = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds?.includes(id)
        ? prevSelectedIds?.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectMonth = (monthYear,allMonthEntries) => {
    const allMonthIds = allMonthEntries?.map((entry) => entry?.id);
    if (allMonthIds?.every((id) => selectedIds?.includes(id))) {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds?.filter((id) => !allMonthIds?.includes(id))
      );
    } else {
      setSelectedIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...allMonthIds]),
      ]);
    }
  };

  const handleSelectAll = () => {
    const allIds = Object.values(groupedLeaveList)
      .flat()
      .map((entry) => entry?.id);
    if (allIds?.every((id) => selectedIds?.includes(id))) {
      setSelectedIds([]);
    } else {
      setSelectedIds(allIds);
    }
  };

  const toggleDetails = (id) => {
    setExpandedSections((prevExpandedSections) =>
      prevExpandedSections?.includes(id)
        ? prevExpandedSections?.filter((section) => section !== id)
        : [...prevExpandedSections, id]
    );
  };

  const handlePageChange = (pageNumber) => {
    setSelectedIds([]);
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setCurrentPage(1);
  };
  const handleSearchChange = (value) => {
    if (value) {
      setSearchQuery(value);
    }
  };

  return (
    <>
      <div className="mx-3 d-block d-sm-flex justify-content-between align-items-center">
        <h5>{approvalLabel.LeaveApproval}</h5>
      </div>

      <div className="rounded email-wrap bookmark-wrap mt-2">
        <CustomPagination
          pageSize={pageSize}
          totalRecords={totalRecords}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          onNextPage={handleNextPage}
          defaultPageSize={10}
          onPreviousPage={handlePreviousPage}
          onPageSizeChange={handlePageSizeChange}
          onSearchChange={handleSearchChange}
          children={
            <>
              <div className="card p-2 my-1 px-4 pt-2">
                <BaseCheckbox
                  id="select-all"
                  checked={
                    Object.values(groupedLeaveList)?.flat()?.length > 0 &&
                    Object.values(groupedLeaveList)
                      ?.flat()
                      ?.every((entry) => selectedIds?.includes(entry?.id))
                  }
                  label={<h5>{approvalLabel.SelectAll}</h5>}
                  onChange={handleSelectAll}
                />
              </div>
              {Object.entries(groupedLeaveList)?.map(([monthYear, leaves]) => (
                <div key={monthYear} className="card month-card p-2 my-1 autoScroll-x overflow-auto">
                  <div className="d-flex justify-content-between align-items-center px-3">
                    <BaseCheckbox
                      className="mt-2"
                      id={`${monthYear}`}
                      checked={leaves?.every((leave) =>
                        selectedIds?.includes(leave?.id)
                      )}
                      onChange={() => handleSelectMonth(monthYear, leaves)}
                      label={<h5>{monthYear}</h5>}
                    />
                    <div className="d-flex align-items-center fs-8">
                      {expandedSections?.includes(monthYear) ? (
                        <RiArrowUpSLine
                          className="ml-2 fs-3"
                          onClick={() => toggleDetails(monthYear)}
                        />
                      ) : (
                        <RiArrowDownSLine
                          className="ml-2 fs-3"
                          onClick={() => toggleDetails(monthYear)}
                        />
                      )}
                    </div>
                  </div>
                  {loader && (
                    <Spinner attrSpinner={{ className: "loader-2" }} />
                  )}
                  {expandedSections?.includes(monthYear) &&
                    leaves?.map((entry) => (
                      <div key={entry.id} className="card month-card p-2 my-1">
                        <div className="details">
                          <div className="card-body">
                            <div className="customTop">
                              <div className="row mt-2">
                                <div className="col-12">
                                  <div className="d-flex gap-4">
                                    <div className="d-flex flex-shrink-0 flex-grow-1">
                                      <BaseCheckbox
                                        id={`staff-${entry?.id}`}
                                        checked={selectedIds?.includes(
                                          entry?.id
                                        )}
                                        onChange={() => {
                                          setModalOpen(false);
                                          handleSelectEntry(entry?.id);
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex flex-shrink-0 flex-grow-1">
                                      {entry?.authUser?.name ||
                                        handleResponse.nullData}
                                    </div>
                                    <div className="d-flex flex-shrink-0 flex-grow-1 align-items-center">
                                      {entry.from_date}
                                    </div>
                                    <div className="d-flex flex-shrink-0 flex-grow-1 align-items-center">
                                      {entry.leave_type}
                                    </div>
                                    <div className="d-flex flex-shrink-0 align-items-center" style={{minWidth: '200px'}}>
                                      <TruncateWithTooltip
                                        text={entry?.description}
                                        maxLength={18}
                                        tooltipId="des"
                                        isOpen={desTooltipOpen}
                                        toggleTooltip={() =>
                                          setDesTooltipOpen(!desTooltipOpen)
                                        }
                                      />
                                    </div>
                                    <div
                                      className="d-flex flex-shrink-0 flex-grow-1 align-items-center"
                                      onClick={() => {
                                        setModalOpen(true);
                                        setSelectedIds([entry?.id]);
                                        setLeaveDetails(entry);
                                      }}
                                    >
                                      {entry?.leave_status}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
              {noResult && (
                <>
                  <div className="py-4 text-center card mt-2">
                    <div>
                      <RiSearchLine className="fs-2" />
                    </div>
                    <div className="mt-4">
                      <h5>{notFound.dataNotFound}</h5>
                    </div>
                  </div>
                </>
              )}
            </>
          }
        />
        <BaseModal
          isOpen={modalOpen}
          title={approvalLabel.LeaveDetails}
          toggler={() => {
            setModalOpen(false);
          }}
          close={() => {
            editData(false);
          }}
          submitText={approvalLabel.Approve}
          closeText={approvalLabel.Reject}
          submit={() => {
            editData(true);
          }}
          disabled={loader}
          loader={loader}
          size="md"
        >
          <>
            <div className="list-group-flush row">
              <div className="col-6">
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.Status}
                  </div>
                  <div className="f-w-600">{leaveDetails?.leave_status}</div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.Staff}
                  </div>
                  <div className="f-w-600">{leaveDetails?.authUser?.name}</div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.LeaveDuration}
                  </div>
                  <div className="f-w-600">
                    {leaveDetails?.from_date} | {leaveDetails?.leave_duration}
                  </div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.LeavesAvailed}
                  </div>
                  <div className="f-w-600">
                    {leaveDetails?.leave_duration} day
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.LeaveType}
                  </div>
                  <div className="f-w-600">{leaveDetails?.leave_type}</div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.LeaveSummary}
                  </div>
                  <div className="f-w-600">
                    <TruncateWithTooltip
                      text={leaveDetails?.leave_summary}
                      maxLength={18}
                      tooltipId="summary"
                      isOpen={summaryTooltipOpen}
                      toggleTooltip={() =>
                        setSummaryTooltipOpen(!summaryTooltipOpen)
                      }
                    />
                  </div>
                </div>
                <div className="mt-2 mb-2">
                  <div className="text-secondary fw-normal fs-16">
                    {approvalLabel.Description}
                  </div>
                  <div className="f-w-600">
                    <TruncateWithTooltip
                      text={leaveDetails?.description}
                      maxLength={18}
                      tooltipId="description"
                      isOpen={tooltipOpen}
                      toggleTooltip={() => setTooltipOpen(!tooltipOpen)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </BaseModal>
        <div className="fixed-bottom-div">
          <div className="row">
            <div className="col-6 d-flex justify-content-start mt-2 ml-4">
              <h5>{`${selectedIds?.length} selected`}</h5>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <BaseButton
                onClick={() => editData(true)}
                label={approvalLabel.Approve}
                className="btn btn-success me-2"
              />
              <BaseButton
                onClick={() => editData(false)}
                label={approvalLabel.Reject}
                className="btn btn-danger"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApproval;